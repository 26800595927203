import {
  type AssetsQuery,
  createDirectus,
  graphql,
  readAssetRaw,
  rest,
} from '@directus/sdk';

export default defineNuxtPlugin(() => {
  const cms = createDirectus<Schema>(useRuntimeConfig().public.cmsUrl)
    .with(graphql())
    .with(rest());

  // sanitize filter query and remove all undefined values
  const request = cms.request;
  cms.request = function (opts: () => any) {
    return request.call(cms, () => {
      const out = opts();
      if (
        out.filter &&
        typeof out.filter === 'object' &&
        Object.keys(out.filter).length === 0
      ) {
        delete out.filter;
      }
      return JSON.parse(JSON.stringify(out));
    });
  } as any;

  const convertAssetUrl = (
    id: DirectusFile['id'] | DirectusFile | null | undefined,
    query?: AssetsQuery,
  ) => {
    if (typeof id === 'string' && !id.includes('/')) {
      const req = readAssetRaw(id, query)();
      const u = new URL(req.path, cms.url);
      u.search = new URLSearchParams(req.params).toString();
      return u.toString();
    } else if (id && typeof id === 'object') {
      const req = readAssetRaw(id.id, query)();
      const u = new URL(req.path, cms.url);
      u.pathname += `/${id.filename_download}`;
      u.search = new URLSearchParams(req.params).toString();
      return u.toString();
    }
    return id;
  };

  const convertAssetUrls = <T extends object>(
    data: T | T[],
    keys: (keyof T)[],
    query?: AssetsQuery,
  ): typeof data => {
    if (Array.isArray(data)) {
      return data.map((x) => convertAssetUrls(x, keys, query) as any);
    }
    data = { ...data };
    for (const key of keys) {
      if (key in data) {
        (data as any)[key] = convertAssetUrl(data[key] as any, query);
      }
    }
    return data;
  };

  return {
    provide: {
      cms: {
        cms,
        convertAssetUrl,
        convertAssetUrls,
      },
    },
  };
});

interface Schema {
  cmla_executive_committees: ExecutiveCommittee[];
  cmla_conference_speakers: Speaker[];
  cmla_news: News[];
  cmla_news_categories: NewsCategory[];
  cmla_2024_conference_agenda: { agenda: AgendaItem[] };
  cmla_2024_conference_partners: ConferencePartner[];
}

export interface DirectusFile {
  id: string;
  charset?: string;
  description?: string;
  duration?: number;
  filename_download?: string;
  filesize?: number;
  height?: number;
  title?: string;
  type?: string;
  width?: number;
}

export interface ExecutiveCommittee {
  id: number;
  group?: string;
  enabled?: boolean;
  sort?: number;
  items: ExecutiveCommitteeItem[];
}

export interface ExecutiveCommitteeItem {
  name?: string;
  title?: string;
  qualifications?: string;
  intro?: string;
  photo?: { key: DirectusFile['id'] };
}

export interface Speaker {
  id: number;
  sort: number;
  name: string;
  title: string;
  qualifications: string;
  intro: string;
  photo: DirectusFile['id'];
  avatar: DirectusFile['id'];
}

export interface NewsCategory {
  id: number;
  name: string;
}

export interface News {
  id: number;
  date_published: string;
  title: string;
  body: string;
  featuredImage: DirectusFile['id'] | DirectusFile | null;
  categories: NewsCategory['id'][];
  photos: (DirectusFile['id'] | DirectusFile)[];
}

export interface AgendaItem {
  time: string;
  title: string;
  speakers?: {
    name: string;
    qualifications?: string;
  }[];
  break?: boolean;
  description?: string;
}

export interface ConferencePartner {
  id: number;
  name: string;
  sort: number;
  image?: DirectusFile['id'];
  href?: string;
  type?: 'Supporting' | 'Co-Organizer';
}
