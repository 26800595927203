<template>
  <NuxtLoadingIndicator color="var(--bs-primary)" />
  <NuxtPage />
</template>

<script setup lang="ts">
import { getGlobalThis } from '@vue/shared';

const {
  app: { baseURL },
  public: { googleAnalyticsId },
} = useRuntimeConfig();

useSeoMeta({
  description:
    "Building a Functioning Society through Peter F. Drucker's Legacy of Management as a Liberal Art",
  author: 'Autron Limited',
  ogImage: `${baseURL}img/CMLA.png`,
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} | CMLA 博雅管理學研中心`
      : 'CMLA 博雅管理學研中心';
  },
  meta: [
    {
      key: 'copyright',
      name: 'copyright',
      content: `${new Date().getFullYear()} The Hang Seng University of Hong Kong`,
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
    {
      name: 'msapplication-TileColor',
      content: '#0063a6',
    },
  ],
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `${baseURL}apple-touch-icon.png`,
    },
    {
      rel: 'icon',
      sizes: '32x32',
      href: `${baseURL}favicon-32x32.png`,
    },
    {
      rel: 'icon',
      sizes: '16x16',
      href: `${baseURL}favicon-16x16.png`,
    },
  ],
  script: googleAnalyticsId
    ? [
        {
          src:
            'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId,
          async: true,
          key: 'gtag',
        },
      ]
    : [],
});

(function (window: any) {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    //@ts-ignore
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', googleAnalyticsId);
})(getGlobalThis());
</script>
