import { default as conferencemV9c3Ve5HzMeta } from "/app/pages/conference.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
export default [
  {
    name: "conference",
    path: "/conference",
    component: () => import("/app/pages/conference.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/app/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/pages/news/index.vue")
  }
]